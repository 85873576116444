<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text">{{ editItem ? 'Edit Approvals' : 'Add Approvals' }}</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="security"
                label="Security"
                :search-input.sync="search"
                :loading="loading.security"
                :items="securityOptions"
                item-text="name"
                hide-details="auto"
                outlined
                dense
                no-filter
                :rules="[v => !!v || 'Please Select Security']"
                @input="selectSecurity"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <ac-input
                v-model="exchange"
                label="Exchange"
                outlined
                :validation-rules="[v => !!v || 'Field is required']"
              ></ac-input>
            </v-col>
            <v-col cols="6">
              <ac-input
                v-model="stokeCode"
                label="Ticker Symbol or Stock Code"
                outlined
                :validation-rules="[v => !!v || 'Field is required']"
              ></ac-input>
            </v-col>
          </v-row>
          <v-col cols="6">
            <ac-date-picker
              v-model="joinDate"
              label="Approval Date"
              outlined
              :validation-rules="[v => !!v || 'Field is required']"
            />
          </v-col>
          <v-row>
            <v-col>
              <ac-select
                label="Buy/Sell"
                v-model="details"
                :items="[
                  { id: 'Buy', name: 'Buy' },
                  { id: 'Sell', name: 'Sell' },
                ]"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
              />
            </v-col>
            <v-col>
              <ac-combo-box
                label="Approver"
                v-model="approver"
                :items="companyApprover"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
              />
            </v-col>
            <v-col>
              <!-- options should be data property  (fixed)-->
              <ac-select
                label="Investment Class"
                v-model="investmentClass"
                :items="investments"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ac-combo-box
                label="User"
                v-model="userData"
                :items="companyUser"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
                @change="filterBroker(userData.id)"
              />
            </v-col>
            <v-col>
              <ac-select
                label="Broker A/C Number "
                v-model="brokersNumber"
                :items="brokers"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
              />
            </v-col>
            <v-col>
              <ac-select
                label="GTC order"
                v-model="gtcOrder"
                :items="[
                  { id: 'Yes', name: 'Yes' },
                  { id: 'No', name: 'No' },
                ]"
                class="company-select"
                :validation-rules="[v => !!v || 'Field is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ac-button
                title="Upload Approval"
                :icon="icons.mdiUpload"
                @click="showUpload = true"
              ></ac-button>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Cancel" color="error" outlined @click="onClose" />
          <ac-button title="Submit" color="success" :loading="loading" @click="onSubmit" />
          <upload-modal v-model="showUpload" title="approval" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// TODO:
// * updated component name (fixed)
// ! REMOVE REDUNDANT AND UNUSED CODE => IMPORTS , DATA PROPERTIES, ICONS (fixed)

import { emailValidator, required } from '@core/utils/validation'
import { mdiUpload } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'upsertApprovalModal',
  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcComboBox: () => import('@/components/AcComboBox.vue'),
    AcDatePicker: () => import('@/components/AcDatePicker'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    UploadModal: () => import('@/components/modals/UploadModal'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editItem: {
      type: Object,
    },
    companyUser: {
      type: Array,
    },
    companyApprover: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiUpload,
      },
      investments: [
        { id: 'Exchange Traded Derivatives', name: 'Exchange Traded Derivatives' },
        { id: 'IPO', name: 'IPO' },
        { id: 'Listed Securities', name: 'Listed Securities' },
        { id: 'Mutual or Authorised Funds', name: 'Mutual or Authorised Funds' },
        { id: 'OTC-Derivatives', name: 'OTC-Derivatives' },
        { id: 'Private Placement', name: 'Private Placement' },
        { id: 'Unauthorised Funds', name: 'Unauthorised Funds' },
        { id: 'Discretionary Account', name: 'Discretionary Account' },
      ],
      details: '',
      investmentClass: '',
      userData: null,
      brokersNumber: '',
      gtcOrder: '',
      showUpload: false,
      search: null,
      valid: false,
      loading: false,
      validators: {
        required,
        emailValidator,
      },
      // * form
      security: null,
      surName: null,
      exchange: null,
      stokeCode: null,
      // selectedCompany: null,
      approver: null,
      joinDate: null,
      brokers: [],
    }
  },

  computed: {
    ...mapGetters('app', ['companies', 'loggedInUser', 'selectedCompany']),
    ...mapGetters('approval', ['accountNumbers', 'getApprovals', 'securityList']),
    securityOptions() {
      if (this.checkObject(this.editItem)) {
        const { exchange, security, ticker } = this.editItem
        const options = [
          {
            name: security,
            ticker,
            exchange: exchange,
          },
        ]
        return options.concat(this.securityList)
      } else {
        return this.securityList
      }
    },
  },
  methods: {
    ...mapActions('approval', ['createApproval', 'updateApproval', 'fetchSecurityList']),
    ...mapMutations('approval', ['SET_APPROVAL', 'GET_ACCOUNT', 'SET_SECURITY']),
    filterBroker(id) {
      this.brokers = this.accountNumbers.filter(account => account.user_id == id)
    },
    // FOR ADD APPROVALS......
    async onSubmit() {
      let name = ''
      let number = ''
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true
        if (this.editItem != undefined) {
          if (this.brokersNumber) {
            let brokerName = this.accountNumbers.filter(account => account.id == this.brokersNumber)
            name = brokerName[0].broker
            number = brokerName[0].name
          }
          const payload = {
            security: this.security,
            exchange: this.exchange,
            symbol: this.stokeCode,
            user: this.userData.id,
            accountNumber: number,
            details: this.details,
            investmentClass: this.investmentClass,
            gtc: this.gtcOrder,
            approver: this.approver.id,
            approvalDate: this.joinDate,
            selectedCompany: this.selectedCompany,
            id: this.editItem.id,
            broker: name,
          }
          const { success, message } = await this.updateApproval(payload)
          if (success) {
            this.AcNotify({
              type: 'success',
              message,
              position: 'bottom',
            })
          } else {
            this.AcNotify({
              type: 'error',
              message,
              position: 'bottom',
            })
          }
        } else {
          if (this.brokersNumber) {
            let brokerName = this.accountNumbers.filter(account => account.id == this.brokersNumber)
            name = brokerName[0].broker
            number = brokerName[0].name
          }

          const payload = {
            security: this.security,
            exchange: this.exchange,
            symbol: this.stokeCode,
            user: this.userData.id,
            accountNumber: number,
            details: this.details,
            investmentClass: this.investmentClass,
            gtc: this.gtcOrder,
            approver: this.approver.id,
            approvalDate: this.joinDate,
            selectedCompany: this.selectedCompany,
            broker: name,
          }
          const { success, message } = await this.createApproval(payload)
          if (success) {
            const notificationType = success ? 'success' : 'error'
            const position = 'bottom'
            this.AcNotify({
              type: notificationType,
              message,
              position,
            })
          }
        }

        this.onClose(true)
        this.loading = false
      }
    },
    onClose(refetch) {
      this.security = null
      this.exchange = null
      this.stokeCode = null
      this.userData = null
      this.brokersNumber = ''
      this.details = ''
      this.investmentClass = ''
      this.gtcOrder = ''
      this.approver = null
      this.joinDate = null
      if (refetch) {
        const { selectedCompany } = this
        this.$emit('onClose', { selectedCompany })
      }

      this.reset()
      this.$emit('input', false)
    },
    async getSecurityList(val) {
      await this.fetchSecurityList(val)
    },
    selectSecurity(val) {
      const { securityList } = this
      const securityObj = securityList.find(obj => {
        return obj.name === val
      })
      if (securityObj != undefined) {
        this.stokeCode = securityObj.symbol
        this.exchange = securityObj.exch
      }
    },
    reset() {
      this.licenses = []
      this.$refs.form.reset()
    },
    getCompanyName(id) {
      const selectedCompany = this.companies.find(o => o.id === id)
      return selectedCompany?.name
    },
  },
  watch: {
    search(val) {
      val && val !== this.secasdasdurity && this.getSecurityList(val)
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.checkObject(this.editItem) && this.editItem != undefined) {
            const {
              accountNumber,
              approvalDate,
              approver,
              buyOrSell,
              exchange,
              gtcOrder,
              investmentClass,
              security,
              ticker,
              userName,
            } = this.editItem
            this.brokers = this.accountNumbers.filter(account => account.name == accountNumber)
            this.security = security
            this.exchange = exchange
            this.stokeCode = ticker
            this.userData = userName
            this.brokersNumber = this.brokers[0].id
            this.details = buyOrSell
            this.investmentClass = investmentClass
            this.gtcOrder = gtcOrder
            this.approver = approver
            this.joinDate = approvalDate
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
